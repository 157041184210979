<template>
  <div data-app>
    <div class="row">
      <p></p>
      <div class="col-md-12">
        <!-- create form start -->
        <v-form ref="form" @submit.prevent="submit" class="custom-form">
          <div class="row">
            <div class="col-md-4 pr-0">
              <div class="bg-white">
                <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                  <span class="card-label font-weight-bolder Main_Blue"
                    >Profile</span
                  >
                </div>
                <div class="col-12 row py-4 m-0">
                  <div class="col-md-6 profile_banner pl-0">
                    <div class="center_bg">
                      <img
                        style="border-radius: 8px; width: 126px; height: 164px"
                        :src="url"
                      />
                      <v-file-input
                        style="font-size: 8px"
                        @change="Preview_image"
                        label="Profile picture"
                        v-model="image"
                      >
                      </v-file-input>
                    </div>
                  </div>
                  <div class="col-md-6 profile_banner pr-0">
                    <div class="center_bg">
                      <img
                        style="border-radius: 8px; width: 126px; height: 164px"
                        :src="url1"
                      />
                      <v-file-input
                        style="font-size: 8px"
                        @change="Preview_image1"
                        label="Banner Image"
                        v-model="image1"
                      >
                      </v-file-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="bg-white">
                <div
                  class="
                    py-2
                    px-4
                    border_b_silver
                    col-12
                    ch_hight
                    d-flex
                    justify-content-between
                  "
                >
                  <span class="card-label font-weight-bolder pt-2 Main_Blue"
                    >Compony Deatils
                  </span>
                  <a
                    class="custom-submit-button btn_submit__ch111"
                    href="/custom-pages/profile"
                  >
                    <span>Edit personal information</span>
                  </a>
                </div>
                <div class="col-12 row m-0">
                  <div class="col-md-6 pl-0 border__input_ch pt-0 pb-0">
                    <v-text-field
                      v-model="companyName"
                      :rules="rules.companyName"
                      label="Company Name*"
                      required
                    ></v-text-field>
                  </div>
                  <div class="col-md-6 pr-0 border__input_ch pt-0 pb-0">
                    <v-text-field
                      v-model="yourDesignation"
                      :rules="rules.yourDesignation"
                      label="Contact person designation*"
                      required
                    ></v-text-field>
                  </div>
                  <div class="col-md-6 pl-0 border__input_ch pt-0 pb-0 row m-0">
                    <div class="col-md-12 p-0">
                      <v-text-field
                        v-model="contactPerson"
                        label="Contact Person Name*"
                        required
                      ></v-text-field>
                    </div>
                    <div class="col-md-6 p-0">
                      <v-text-field
                        v-model="establishedIn"
                        :rules="rules.establishedIn"
                        label="Established in year*"
                        required
                      ></v-text-field>
                    </div>
                    <div class="col-md-6 pr-0">
                      <v-combobox
                        v-model="employees"
                        :items="employeesOptions"
                        size="sm"
                        class="border__input_ch"
                        label="No. of employees*"
                        chips
                      >
                        <template>
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                      <!-- <b-form-select
                        v-model="employees"
                        :options="employeesOptions"
                        :rules="rules.employees"
                        size="sm"
                        class="mt-3"
                        required
                      ></b-form-select> -->
                    </div>
                  </div>
                  <div
                    class="
                      col-md-6
                      pr-0
                      border__input_ch
                      pt-0
                      pb-0
                      row
                      m-0
                      min_hight_by_ch
                    "
                  >
                    <v-combobox
                      v-model="industries"
                      :items="industriesOptions"
                      label="Industries*"
                      multiple
                      class="border__input_ch"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          @click:close="data.parent.selectItem(data.item)"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="row m-0 px-4" style="background: white">
                <div class="col-md-4 pl-0 pr-0 border__input_ch">
                  <v-text-field
                    v-model="email"
                    :rules="rules.email"
                    label="Email Address*"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-4 pl-4 pr-0 border__input_ch">
                  <v-text-field
                    v-model="website"
                    label="Website*"
                  ></v-text-field>
                </div>
                <div class="col-md-4 pr-0 border__input_ch">
                  <v-text-field
                    v-model="phoneNumber"
                    :rules="rules.phoneNumber"
                    label="Phone Number*"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-4 pl-0 pr-0 border__input_ch">
                  <v-text-field
                    v-model="officePhone1"
                    :rules="rules.officePhone1"
                    label="Office Phone 1"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-4 pr-0 border__input_ch">
                  <v-text-field
                    v-model="officePhone2"
                    label="Office Phone 2"
                  ></v-text-field>
                </div>
                <div class="col-md-4 pr-0 border__input_ch">
                  <v-text-field
                    v-model="linkedin"
                    label="LinkedIn profile"
                  ></v-text-field>
                </div>
                <div class="col-md-4 pl-0 pr-0 border__input_ch">
                  <v-text-field
                    v-model="facebook"
                    label="Facebook profile"
                  ></v-text-field>
                </div>
                <div class="col-md-4 pr-0 border__input_ch">
                  <v-text-field
                    v-model="instagram"
                    label="Instagram profile"
                  ></v-text-field>
                </div>
                <div class="col-md-4 pr-0 border__input_ch">
                  <v-text-field
                    v-model="twitter"
                    label="Twitter profile"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="col-12 row m-0" style="background: #eef0f8">
              <div class="bg-white mt-4 p-0">
                <div class="col-12 p-0">
                  <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                    <span class="card-label font-weight-bolder Main_Blue"
                      >Addresses & Branches</span
                    >
                  </div>
                </div>
                <div class="col-12 py-4">
                  <div
                    class="row"
                    v-for="(item, index) in branches"
                    :key="index"
                  >
                    <div class="col-md-3 pr-0 border__input_ch">
                      <v-text-field
                        v-model="item.branchName"
                        label="Branch Name*"
                        class="border__input_ch"
                      ></v-text-field>
                    </div>
                    <div class="col-md-3 pr-0 border__input_ch">
                      <v-text-field
                        v-model="item.branchAddress"
                        label="Address*"
                        class="border__input_ch"
                      ></v-text-field>
                    </div>
                    <div class="col-md-2 pr-0 border__input_ch">
                      <v-text-field
                        v-model="item.phoneNumber"
                        label="Phone Number"
                        class="border__input_ch"
                      ></v-text-field>
                    </div>
                    <div class="col-md-2 border__input_ch">
                      <v-text-field
                        v-model="item.email"
                        label="Email"
                        class="border__input_ch"
                      ></v-text-field>
                    </div>
                    <div
                      class="col-md-2 pl-0"
                      style="display: flex; margin-top: 1.2%; margin-left: 0%"
                    >
                      <v-btn
                        class="custom-add-new-record-button btn_row_ch"
                        type="button"
                        @click="addNewRow()"
                        >Add</v-btn
                      >
                      <v-btn
                        v-if="index > 0"
                        class="custom-delete-new-record-button btn_row_ch"
                        type="button"
                        @click="deleteRow(index)"
                        >Del</v-btn
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 row m-0" style="background: #eef0f8">
              <div class="bg-white mt-4 p-0">
                <div class="col-12 p-0">
                  <div class="py-4 px-4 border_b_silver col-12 ch_hight">
                    <span class="card-label font-weight-bolder Main_Blue"
                      >About your company*</span
                    >
                  </div>
                </div>
                <div class="col-12 py-4">
                  <vue-editor
                    v-model="aboutCompany"
                    outlined
                    name="input-7-4"
                    label="About Your Company"
                    rows="8"
                    max="832"
                  ></vue-editor>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="bg-white py-4 px-2">
                <v-card-actions>
                  <v-btn
                    class="custom-submit-button btn_submit__ch"
                    type="submit"
                    ><b-spinner small type="grow" v-if="submitting"></b-spinner>
                    <span>Update</span>
                  </v-btn>
                  <v-btn
                    text
                    @click="cancel"
                    class="custom-cancel-button btn_submit__ch_cls"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </div>
            </div>
          </div>
        </v-form>
        <v-card>
          <div style="">
            <v-form
              ref="form"
              @submit.prevent="submit"
              class="custom-form d-none"
            >
              <div class="row">
                <div class="col-md-3">
                  <img
                    style="border-radius: 8px; width: 126px; height: 126px"
                    :src="url"
                  />
                  <v-file-input
                    style="font-size: 8px"
                    label="Profile picture"
                    @change="Preview_image"
                    v-model="image"
                  >
                  </v-file-input>
                </div>
                <div class="col-md-3">
                  <img
                    style="border-radius: 8px; width: 126px; height: 126px"
                    :src="url1"
                  />
                  <v-file-input
                    style="font-size: 8px"
                    label="Banner Image"
                    @change="Preview_image1"
                    v-model="image1"
                  >
                  </v-file-input>
                </div>
                <div class="col-md-2 border__input_ch">
                  <v-text-field
                    v-model="companyName"
                    :rules="rules.companyName"
                    label="Company Name"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-2">
                  <v-text-field
                    v-model="yourDesignation"
                    :rules="rules.yourDesignation"
                    label="Contact person designation"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-2">
                  <v-text-field
                    v-model="establishedIn"
                    :rules="rules.establishedIn"
                    label="Established in year"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-4">
                  <v-combobox
                    v-model="industries"
                    :items="industriesOptions"
                    label="Industries"
                    multiple
                    size="sm"
                    class="mt-3"
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-md-2">
                  <v-text-field
                    v-model="contactPerson"
                    label="Contact Person Name"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-4">
                  <!-- <div
                    class="custom-control custom-radio"
                    style="display: flex; flex-direction: column"
                  >
                    <label>Recruiter Type</label>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                      style="margin-left: 5%"
                    >
                      <div class="form-group mb-0">
                        <input
                          type="radio"
                          id="Direct Employer"
                          name="Direct Employer"
                          class="custom-control-input"
                          value="Direct Employer"
                          v-model="recruiter_type"
                        />
                        <label
                          class="custom-control-label"
                          for="Direct Employer"
                          >Direct Employer</label
                        >
                      </div>
                    </div>

                    <div
                      class="custom-control custom-radio custom-control-inline"
                      style="margin-left: 5%"
                    >
                      <div class="form-group mb-0">
                        <input
                          type="radio"
                          id="Recruitment Agency"
                          name="Recruitment Agency"
                          class="custom-control-input"
                          value="Recruitment Agency"
                          v-model="recruiter_type"
                        />
                        <label
                          class="custom-control-label"
                          for="Recruitment Agency"
                          >Recruitment Agency
                        </label>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <v-text-field
                    v-model="officePhone1"
                    :rules="rules.officePhone1"
                    label="Office Phone 1"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-model="officePhone2"
                    label="Office Phone 2"
                  ></v-text-field>
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-model="email"
                    :rules="rules.email"
                    label="Email Address"
                    required
                  ></v-text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <v-text-field
                    v-model="website"
                    label="Website"
                  ></v-text-field>
                </div>
                <div class="col-md-4">
                  <v-text-field
                    v-model="phoneNumber"
                    :rules="rules.phoneNumber"
                    label="Phone Number"
                    required
                  ></v-text-field>
                </div>

                <div class="col-md-4" style="margin-top: 1%">
                  <!-- <b-form-select
                    v-model="employees"
                    :options="employeesOptions"
                    :rules="rules.employees"
                    size="sm"
                    class="mt-3"
                    required
                  ></b-form-select> -->
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <v-text-field
                    v-model="linkedin"
                    label="LinkedIn profile"
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field
                    v-model="facebook"
                    label="Facebook profile"
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field
                    v-model="instagram"
                    label="Instagram profile"
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field
                    v-model="twitter"
                    label="Twitter profile"
                  ></v-text-field>
                </div>
              </div>

              <v-card elevation="2" style="padding: 2%">
                <label style="font-weight: bold">Address Details</label>
                <div
                  class="row"
                  :style="
                    index == 0
                      ? 'margin-top: 0%; '
                      : 'margin-top: 2%; ' +
                        'padding-left: 1%; padding-right: 1%'
                  "
                  v-for="(item, index) in branches"
                  :key="index"
                >
                  <div class="col-md-3">
                    <v-text-field
                      v-model="item.branchName"
                      label="Branch Name"
                    ></v-text-field>
                  </div>
                  <div class="col-md-3">
                    <v-text-field
                      v-model="item.branchAddress"
                      label="Address"
                    ></v-text-field>
                  </div>
                  <div class="col-md-3">
                    <v-text-field
                      v-model="item.phoneNumber"
                      label="Phone Number"
                    ></v-text-field>
                  </div>
                  <div class="col-md-2">
                    <v-text-field
                      v-model="item.email"
                      label="Email"
                    ></v-text-field>
                  </div>
                  <div class="col-md-1" style="display: flex; margin-top: 2%">
                    <v-btn
                      class="custom-add-new-record-button v-size--small"
                      type="button"
                      @click="addNewRow()"
                      >+</v-btn
                    >
                    <v-btn
                      v-if="index > 0"
                      class="custom-delete-new-record-button v-size--small"
                      type="button"
                      @click="deleteRow(index)"
                      >-</v-btn
                    >
                  </div>
                </div>
              </v-card>

              <v-card elevation="2" style="padding: 2%">
                <label style="font-weight: bold">Security Questions</label>
                <div
                  class="row"
                  :style="
                    index == 0
                      ? 'margin-top: 0%; '
                      : 'margin-top: 2%; ' +
                        'padding-left: 1%; padding-right: 1%'
                  "
                  v-for="(item, index) in security_questions"
                  :key="index"
                >
                  <div class="col-md-3">
                    <v-text-field
                      v-model="item.question"
                      label="Question"
                    ></v-text-field>
                  </div>
                  <div class="col-md-3">
                    <v-text-field
                      v-model="item.answer"
                      label="Answer"
                    ></v-text-field>
                  </div>
                  <div
                    class="col-md-1"
                    style="display: flex; margin-top: 2%"
                    v-if="security_questions.length < 2"
                  >
                    <v-btn
                      class="custom-add-new-record-button v-size--small"
                      type="button"
                      @click="addNewRow1()"
                      >+</v-btn
                    >
                    <v-btn
                      v-if="index > 0"
                      class="custom-delete-new-record-button v-size--small"
                      type="button"
                      @click="deleteRow1(index)"
                      >-</v-btn
                    >
                  </div>
                </div>
              </v-card>

              <!-- <div class="row" style="margin-top: 2%">
                <div class="col-md-6">
                  <label>Mission Statement</label>
                  <vue-editor
                    v-model="missionStatement"
                    outlined
                    name="input-7-4"
                    label="Mission Statement"
                    rows="6"
                  ></vue-editor>
                </div>
                
                <div class="col-md-6">
                   <label>Vision Statement</label>
                  <vue-editor
                    v-model="visionStatement"
                    outlined
                    name="input-7-4"
                    label="Vision Statement"
                    rows="6"
                  ></vue-editor>
                </div>
              </div> -->

              <div class="row" style="margin-top: 2%">
                <div class="col-md-12">
                  <label>About Your Company</label>
                  <vue-editor
                    v-model="aboutCompany"
                    outlined
                    name="input-7-4"
                    label="About Your Company"
                    rows="8"
                    max="832"
                  ></vue-editor>
                </div>
              </div>
              <!-- <div
                class="row"
                style="
                  margin-top: 2%;
                  display: flex;
                  flex-direction: column;
                  padding-left: 1.8%;
                "
              >
                <label>Terms & Conditions</label>
                <v-checkbox
                  v-model="checkbox"
                  label="I accept the Terms & Conditions and Privacy policy"
                ></v-checkbox>
              </div> -->

              <v-card-actions style="margin-top: 3%">
                <v-btn
                  :disabled="submitting"
                  class="custom-submit-button"
                  type="submit"
                  ><b-spinner small type="grow" v-if="submitting"></b-spinner>
                  <span>Submit</span>
                </v-btn>
                <v-btn text @click="cancel" class="custom-cancel-button"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-form>
          </div>
        </v-card>
        <!-- create form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";
import GeneralService from "@/MainServices/GeneralService.js";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      mediaUrl: MEDIA_URL,
      url: "",

      url1: "",
      image: null,
      image1: null,
      form: [""],
      search: null,
      loading: true,
      success: false,
      user_id:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")).id
          : null,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      submitting: false,
      companyName: "",
      recruiter_type: "Direct Employer",
      yourDesignation: "",
      officePhone1: "",
      officePhone2: "",
      email:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")).email
          : "",
      establishedIn: "",
      website: "",
      phoneNumber: "",
      jobFunction: "",
      linkedin: "",
      facebook: "",
      instagram: "",
      twitter: "",
      employees: null,
      branches: [
        { branchName: "", branchAddress: "", email: "", phoneNumber: "" },
      ],
      security_questions: [{ question: "", answer: "" }],
      missionStatement: "",
      visionStatement: "",
      aboutCompany: "",
      checkbox: false,
      rules: {
        companyName: [
          (val) => (val || "").length > 0 || "Company name is required",
        ],
        yourDesignation: [
          (val) => (val || "").length > 0 || "Your designation is required",
        ],
        // officePhone1: [
        //   (val) => (val || "").length > 0 || "Office phone number is required",
        // ],
        // email: [(val) => (val || "").length > 0 || "Email is required"],
        establishedIn: [
          (val) => (val || "").length > 0 || "Established In field required",
        ],
        phoneNumber: [
          (val) => (val || "").length > 0 || "Phone number is required",
        ],
        employees: [
          (val) => (val || "").length > 0 || "No. of employees required",
        ],
      },
      data: [],
      jobFunctionsOptions: [],
      employeesOptions: [
        { value: null, text: "No. of Employees" },
        { value: "1-10", text: "1-10" },
        { value: "11-25", text: "11-25" },
        { value: "26-50", text: "26-50" },
        { value: "51-100", text: "51-100" },
        { value: "101-250", text: "101-250" },
        { value: "251-500", text: "251-500" },
        { value: "500-1000", text: "500-1000" },
        { value: "1000+", text: "1000+" },
      ],
      industries: null,
      industriesOptions: [],
      contactPerson: "",
    };
  },

  validations: {
    companyName: {
      required,
      minLength: minLength(4),
    },
    yourDesignation: {
      required,
      minLength: minLength(4),
    },
    officePhone1: {
      required,
      minLength: minLength(7),
    },
    establishedIn: {
      required,
      minLength: minLength(4),
    },
    email: {
      required,
      minLength: minLength(4),
    },
    phoneNumber: {
      required,
      minLength: minLength(7),
    },
    // jobFunction: {
    //   required,
    // },
    employees: {
      required,
    },
    checkbox: { sameAs: (val) => val === true },
  },
  components: {
    VueEditor,
  },
  mounted() {
    this.getInititalData();
  },
  methods: {
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    Preview_image1() {
      this.url1 = URL.createObjectURL(this.image1);
    },
    getInititalData() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/recruiter-profile/${this.user_id}`)
        .then((res) => {
          if (res.data.profile_pic && res.data.profile_pic.length > 0) {
            this.url = this.mediaUrl + res.data.profile_pic;
          }
          if (res.data.banner && res.data.banner.length > 0) {
            this.url1 = this.mediaUrl + res.data.banner;
          }
          this.companyName = res.data.company_name;
          this.contactPerson = res.data.contact_person;
          this.recruiter_type = res.data.recruiter_type;
          this.yourDesignation = res.data.your_designation;
          if (res.data.office_phone_1 == "null") {
            this.officePhone1 = "";
          } else {
            this.officePhone1 = res.data.office_phone_1;
          }
          if (res.data.office_phone_2 == "null") {
            this.officePhone2 = "";
          } else {
            this.officePhone2 = res.data.office_phone_2;
          }
          if (res.data.established_in == "null") {
            this.establishedIn = "";
          } else {
            this.establishedIn = res.data.established_in;
          }
          if (res.data.linkedin == "null") {
            this.linkedin = "";
          } else {
            this.linkedin = res.data.linkedin;
          }
          if (res.data.facebook == "null") {
            this.facebook = "";
          } else {
            this.facebook = res.data.facebook;
          }
          if (res.data.instagram == "null") {
            this.instagram = "";
          } else {
            this.instagram = res.data.instagram;
          }
          if (res.data.twitter == "null") {
            this.twitter = "";
          } else {
            this.twitter = res.data.twitter;
          }
          if (res.data.website == "null") {
            this.website = "";
          } else {
            this.website = res.data.website;
          }
          this.phoneNumber = res.data.phone_number;
          if (res.data.job_function && res.data.job_function.length > 0) {
            this.jobFunction = res.data.job_function;
          }

          this.employees = res.data.employees;
          if (res.data.branches && res.data.branches.length > 0) {
            this.branches = res.data.branches;
          }

          if (
            res.data.security_questions &&
            res.data.security_questions.length > 0
          ) {
            this.security_questions = res.data.security_questions;
          }
          if (res.data.mission_statement == "null") {
            this.missionStatement = "";
          } else {
            this.missionStatement = res.data.mission_statement;
          }
          if (res.data.vision_statement == "null") {
            this.visionStatement = "";
          } else {
            this.visionStatement = res.data.vision_statement;
          }
          if (
            res.data.about_company == "null" ||
            res.data.about_company == null
          ) {
            this.aboutCompany = "";
          } else {
            this.aboutCompany = res.data.about_company;
          }

          if (res.data.industries && res.data.industries.length > 0) {
            this.industries = res.data.industries;
          }
          res.data.industriesOptions.forEach((element) => {
            this.industriesOptions.push(element);
          });

          res.data.jobFunction.forEach((element) => {
            this.jobFunctionsOptions.push({
              value: element.job_function_name,
              text: element.job_function_name,
            });
          });
          this.jobFunctionsOptions.unshift(
            ...[{ value: null, text: "Job function" }]
          );
        })
        .catch(() => {
          // Swal.fire({
          //   title: "",
          //   text: "Unable to fetch profile!",
          //   icon: "error",
          //   confirmButtonClass: "btn btn-secondary",
          //   heightAuto: false,
          // });
        });
    },
    addNewRow() {
      this.branches.push({
        branchName: "",
        branchAddress: "",
        email: "",
        phoneNumber: "",
      });
    },
    deleteRow(index) {
      var temp = [];
      this.branches.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.branches = temp;
    },
    addNewRow1() {
      this.security_questions.push({
        question: "",
        answer: "",
      });
    },
    deleteRow1(index) {
      var temp = [];
      this.security_questions.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.security_questions = temp;
    },
    submit() {
      if (this.companyName == "" || this.companyName == null) {
        Swal.fire({
          title: "",
          text: "Company name is require",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.yourDesignation == "" || this.yourDesignation == null) {
        Swal.fire({
          title: "",
          text: "Please enter your designation",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.contactPerson == "" || this.contactPerson == null) {
        Swal.fire({
          title: "",
          text: "Please enter your contact person name",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.establishedIn == "" || this.establishedIn == null) {
        Swal.fire({
          title: "",
          text: "Please enter company established year",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.employees == "" || this.employees == null) {
        Swal.fire({
          title: "",
          text: "Please select number of employees",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.industries == "" || this.industries == null) {
        Swal.fire({
          title: "",
          text: "Please select at last one industrie",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.email == "" || this.email == null) {
        Swal.fire({
          title: "",
          text: "Enter company's email address",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.phoneNumber == "" || this.phoneNumber == null) {
        Swal.fire({
          title: "",
          text: "Please enter your mobile number",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.website == "" || this.website == null) {
        Swal.fire({
          title: "",
          text: "Please add your website link",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (
        this.branches[0].branchName == "" ||
        this.branches[0].branchName == null ||
        this.branches[0].branchAddress == "" ||
        this.branches[0].branchAddress == null
      ) {
        Swal.fire({
          title: "",
          text: "Please add your first branch Deatails",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (this.aboutCompany == "" || this.aboutCompany == null) {
        Swal.fire({
          title: "",
          text: "Please add your about company",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      this.submitting = true;
      let formData = new FormData();
      formData.append("profile_pic", this.image);
      formData.append("banner", this.image1);
      formData.append("recruiter_type", this.recruiter_type);
      formData.append("companyName", this.companyName);
      formData.append("contactPerson", this.contactPerson);
      formData.append("yourDesignation", this.yourDesignation);
      formData.append("officePhone1", this.officePhone1);
      formData.append("officePhone2", this.officePhone2);
      formData.append("email", this.email);
      formData.append("establishedIn", this.establishedIn);
      formData.append("website", this.website);
      formData.append("phoneNumber", this.phoneNumber);
      formData.append("jobFunction", this.jobFunction);
      formData.append("linkedin", this.linkedin);
      formData.append("facebook", this.facebook);
      formData.append("instagram", this.instagram);
      formData.append("twitter", this.twitter);
      formData.append("employees", this.employees.value);
      formData.append("branches", JSON.stringify(this.branches));
      formData.append(
        "security_questions",
        JSON.stringify(this.security_questions)
      );
      formData.append("industries", JSON.stringify(this.industries));
      // formData.append("missionStatement", this.missionStatement);
      // formData.append("visionStatement", this.visionStatement);
      formData.append("aboutCompany", this.aboutCompany);

      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/recruiter-profile/${this.user_id}`, formData)
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.submitting = false;
          if (res.data == "company") {
            Swal.fire({
              title:
                "Company name already register please change your company name",
              text: "",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            Swal.fire({
              title: "",
              text: "Company profile updated",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.submitting = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    cancel() {
      this.resetForm();
      this.showFormData = false;
    },
    resetForm() {
      this.form = [""];
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },
  },
};
</script>
